import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {CustomValidators} from '../../../../shared/services/custom.validators';
import {EmployeeService} from '../../../../shared/services/employee.service';
import {GlobalFunctionsService} from '../../../../shared/services/globalFunctions.service';
import {EmployeeGroup} from '../../../../shared/models/employee/employeeGroup';
import {Employee} from '../../../../shared/models/employee/employee';

@Component({
  selector: 'employee',
  templateUrl: 'employee.component.html',
  styleUrls: ['./employee.component.scss']
})

export class EmployeeComponent implements OnInit {
  constructor(private fb: UntypedFormBuilder, private router: Router, public dialog: MatDialog,
              private route: ActivatedRoute, public dialogRef: MatDialogRef<EmployeeComponent>,
              @Inject(MAT_DIALOG_DATA) public employee: Employee, public globalFunctionsService: GlobalFunctionsService,
              public customVal: CustomValidators, private employeeService: EmployeeService,
              private toastrService: ToastrService
  ) {
  }

  public employeeId = GlobalFunctionsService.employee.employeeId;
  public employeeGroups: EmployeeGroup[] = [];
  public employeeForm = this.fb.group({
    employeeId: [''],
    createdOn: [''],
    createdBy: [''],
    modifiedOn: [''],
    modifiedBy: [''],
    active: [''],
    employeeGroup: ['', [Validators.required]],
    email: ['', [Validators.required, CustomValidators.email]],
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
    passwordRepeat: [''],
  }, {validator: CustomValidators.passwordMatch2});

  formErrors = {
    'email': '',
    'username': '',
    'employeeGroup': '',
    'password': '',
    'passwordRepeat': '',
  };

  ngOnInit() {
    if (this.employee) {
      this.employeeForm = this.fb.group({
        employeeId: [''],
        createdOn: [''],
        createdBy: [''],
        modifiedOn: [''],
        modifiedBy: [''],
        active: [''],
        employeeGroup: ['', [Validators.required]],
        email: ['', [Validators.required, CustomValidators.email]],
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
        passwordRepeat: [''],
      }, {validator: CustomValidators.passwordMatch});
    }


    GlobalFunctionsService.dialogCloseConfirm(this.dialogRef, this.employeeForm);
    if (this.employee) {
      this.employeeForm.setValue({
        employeeId: this.employee.employeeId,
        createdOn: this.employee.createdOn,
        createdBy: this.employee.createdBy,
        modifiedOn: this.employee.modifiedOn,
        modifiedBy: this.employee.modifiedBy,
        active: this.employee.active,
        employeeGroup: this.employee.employeeGroup?.name,
        email: this.employee.email,
        username: this.employee.username,
        password: null,
        passwordRepeat: null,
      });
    }
    this.employeeService.getEmployeeGroup()
      .subscribe(success => {
        this.employeeGroups = success;
      });
  }

  onSubmit() {
    this.customVal.submitClicked = true;
    this.customVal.logValidationErrors(this.employeeForm, this.formErrors);
    if (this.employeeForm.valid) {
      if (this.employee) {
        const employee: Employee = {
          employeeId: this.employeeForm.value.employeeId,
          createdBy: this.employeeForm.value.createdBy,
          createdOn: this.employeeForm.value.createdOn,
          modifiedBy: this.employeeId,
          modifiedOn: new Date(),
          active: true,
          email: this.employeeForm.value.email,
          username: this.employeeForm.value.username,
          password: this.employeeForm.value.password,
          employeeGroupId: (this.employeeGroups.find(s => s.name === this.employeeForm.value.employeeGroup)?.employeeGroupId),
        };
        this.employeeService.updateEmployee(employee).subscribe(
          () => {
            this.toastrService.success('Opdateret brugeren ' + this.employeeForm.value.username, 'Bruger');
            this.dialogRef.close();
          },
          err => {
            this.toastrService.error('Brugernavn er allerede taget', 'Bruger');
          }
        );
      } else {
        const employee: Employee = {
          employeeId: 0,
          createdBy: this.employeeId,
          createdOn: new Date(),
          modifiedBy: this.employeeId,
          modifiedOn: new Date(),
          active: true,
          email: this.employeeForm.value.email,
          username: this.employeeForm.value.username,
          password: this.employeeForm.value.password,
          employeeGroupId: (this.employeeGroups.find(s => s.name === this.employeeForm.value.employeeGroup)?.employeeGroupId),
        };
        this.employeeService.insertEmployee(employee).subscribe(
          () => {
            this.toastrService.success('Oprettede brugeren ' + this.employeeForm.value.username, 'Bruger');
            this.dialogRef.close();
          },
          err => {
            this.toastrService.error('Brugernavn er allerede taget', 'Bruger');
          }
        );
      }
    }
  }
}

<div class="card card-login mx-auto mt-5">
  <div class="card-header bg-white"><img src="../../../assets/img/logo.png" width="100%" alt=""></div>
  <div class="card-body">
    <form [formGroup]="loginForm" class="mb-0" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class=" form-group">
        <label for="userName">Brugernavn</label>
        <input class="form-control" id="userName" name="userName" formControlName="username" tabindex="1" required
               autoFocus>
      </div>
      <div class="form-group">
        <label for="password">Adgangskode</label>
        <input type="password" class="form-control" id="password" name="password" formControlName="password"
               tabindex="2" required>
      </div>
        <button type="submit" tabindex="3" class="btn btn-primary w-100">Log Ind</button>
        <a class="pointer text-center d-block pt-2" (click)="forgotPassword()">Glemt adgangskode</a>
    </form>
  </div>
</div>
<div class="col text-center mt-2">
  <p class="text-muted"><small>Udviklet af <a target="_blank" class="text-muted" href="https://itoperators.dk/">IT
    Operators ApS</a></small></p>
</div>


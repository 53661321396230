import {FormControl, FormGroup, Validators} from '@angular/forms';

import {Component, OnInit} from '@angular/core';
import {CustomValidators} from '../../../shared/services/custom.validators';
import {EmployeeService} from '../../../shared/services/employee.service';
import {GlobalFunctionsService} from '../../../shared/services/globalFunctions.service';
import {Employee} from '../../../shared/models/employee/employee';

@Component({
  selector: 'employeePasswordComponent',
  templateUrl: 'employeePassword.component.html',
  styleUrls: ['./employeePassword.component.scss']

})
export class EmployeePasswordComponent implements OnInit {
  constructor(public customVal: CustomValidators,
              public globalFunctionsService: GlobalFunctionsService,
              private employeeService: EmployeeService) {
  }

  employeePasswordForm = new FormGroup({
    password: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    passwordRepeat: new FormControl(''),
    employeeId: new FormControl(''),
  });

  public employeeId = GlobalFunctionsService.employee.employeeId;
  public username = GlobalFunctionsService.employee.username;

  formErrors = {
    'password': '',
    'passwordRepeat': '',
  };

  ngOnInit() {
    this.employeePasswordForm.addValidators(
      CustomValidators.matchValidator(this.employeePasswordForm.get('password'), this.employeePasswordForm.get('passwordRepeat'))
    );
  }

  onSubmit() {
    this.customVal.submitClicked = true;
    this.customVal.logValidationErrors(this.employeePasswordForm, this.formErrors);
    if (this.employeePasswordForm.valid) {
      const employee: Employee = {
        employeeId: this.employeeId,
        createdBy: this.employeeId,
        createdOn: new Date(),
        modifiedBy: this.employeeId,
        modifiedOn: new Date(),
        active: true,
        email: null,
        username: null,
        employeeGroupId: null,
        password: this.employeePasswordForm.getRawValue().password,
      };
      this.employeeService.updateEmployee(employee).subscribe(
        () => {
          this.globalFunctionsService.successToast('Opdateret adgangskode');
          this.employeePasswordForm.reset();
        }
      );
    }
  }
}

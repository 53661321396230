<div class="row">
  <div class="col mb-4">
    <div class="pageTitle d-flex align-items-center">
      <h1 class="mb-0">Frontpage</h1>
      <button class="btn btn-outline-primary btn-sm ms-3">Action button</button>

      <div class="d-flex align-middle ms-3">
        <label class="switch">
          <input type="checkbox" id="togBtnNormalProject">
          <!-- [checked]="marked" (change)="toggleVisibility($event)" -->
          <div class="slider round"><span class="on">Projekt</span> <span class="off">Normal</span></div>
        </label>
      </div>

    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-6 mb-4">
    <div class="card card-body">
      <h2>Text content</h2>

      <h1>Lorem ipsum dolor sit amet (h1)</h1>
      <h2>Lorem ipsum dolor sit amet (h2)</h2>
      <h3>Lorem ipsum dolor sit amet (h3)</h3>
      <h4>Lorem ipsum (h4)</h4>
      <h5>Lorem ipsum (h5)</h5>
      <h6>Lorem ipsum (h6)</h6>

      <p>Denne side bruges til at opsætte designet. <strong>Den har alle elementerne</strong> som er brugt i <a href="">designfilen</a>.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Hejsa!</p>
    </div>
  </div>
  <div class="col-lg-6 mb-4">
    <div class="card card-body">
      <h2>Form content</h2>
      <form>
        <div class="form-group">
          <label for="exampleFormControlInput1">Email address</label>
          <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com">
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect1">Example select</label>
          <select class="form-control" id="exampleFormControlSelect1">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect2">Example multiple select</label>
          <select multiple class="form-control" id="exampleFormControlSelect2">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div class="form-group">
          <label for="exampleFormControlTextarea1">Example textarea</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
        <div class="row">
          <div class="form-group col">
            <label for="exampleFormControlInput2">First name</label>
            <input type="text" class="form-control is-valid" id="exampleFormControlInput2" placeholder="First name">
            <div class="valid-feedback">
              Looks good!
            </div>
          </div>
          <div class="form-group col">
            <label for="exampleFormControlInput3">Last name</label>
            <input type="text" class="form-control is-invalid" id="exampleFormControlInput3" placeholder="Last name">
            <div class="invalid-feedback">
              Please choose a username.
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary mb-2">Submit</button>
      </form>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-body">
        <h2>Smaller centered container</h2>

        <p>Hello world.</p>
      </div>
    </div>
  </div>
</div>

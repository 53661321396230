import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {EmployeeComponent} from '../employee/employee.component';
import {Employee} from '../../../../shared/models/employee/employee';
import {EmployeeService} from '../../../../shared/services/employee.service';
import {GlobalFunctionsService} from '../../../../shared/services/globalFunctions.service';
import {DialogService} from '../../../../shared/dialogs/dialog.service';
import {ToastrService} from 'ngx-toastr';
import {EmployeeGroup} from '../../../../shared/models/employee/employeeGroup';

@Component({
  selector: 'employeeView',
  templateUrl: 'employeeView.component.html',
  styleUrls: ['./employeeView.component.scss']
})

export class EmployeeViewComponent implements OnInit {
  constructor(public dialog: MatDialog,
              public globalFunctionsService: GlobalFunctionsService, private employeeService: EmployeeService,
              private dialogSrv: DialogService, private toastrService: ToastrService
  ) {
  }

  dataSource: MatTableDataSource<Employee> = new MatTableDataSource<Employee>();
  @ViewChild(MatSort, {static: false}) sort: MatSort | null = null;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator | null = null;
  displayedColumns = ['username', 'email', 'employeeGroupId', 'modifiedBy', 'modifiedOn', 'actions'];
  searchKey = '';
  public employeeGroups: EmployeeGroup[] = [];

  ngOnInit() {
    this.load();
  }

  load() {
    this.employeeService.getEmployeeGroup().subscribe(
      success => {
        this.employeeGroups = success;
      });

    this.employeeService.getEmployees().subscribe(
      success => {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.data = success;
      });
  }

  getNameByEmployeeGroup(employeeGroupId: number) {
    return this.employeeGroups.find(a => a.employeeGroupId === employeeGroupId)?.name;
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLocaleLowerCase();
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyFilter();
  }

  onCreate() {
    const dialogRef = this.dialog.open(EmployeeComponent, GlobalFunctionsService.matDialogConfigStandard());
    dialogRef.afterClosed().subscribe(() => {
      this.load();
    });
  }

  onEdit(employee: Employee) {
    const dialogRef = this.dialog.open(EmployeeComponent, GlobalFunctionsService.matDialogConfigStandard(employee));
    dialogRef.afterClosed().subscribe(() => {
      this.load();
    });
  }

  onDelete(row: Employee) {
    if (!this.dialogSrv.modalOpen) {
      this.dialogSrv.modalOpen = true;
      this.dialogSrv.openConfirmDialog('Er du sikker på du vil slette ' + row.username, 'Sletning af bruger', 'Ja ', 'Annullere')
        .afterClosed().subscribe(res => {
        if (res) {
          this.employeeService.disableEmployee(row.employeeId).subscribe(
            () => {
              this.load();
              this.toastrService.success('Deaktiveret: ' + row.username, 'Administration');
            }
          );
        }
        this.dialogSrv.modalOpen = false;
      });
    }
  }
}

<div>
  <div class="mat-dialog-title">
    <span>{{data.header}}</span>
  </div>
  <div class="content-container mb-3">
    <span class="content-span full-width">{{data.message}}</span>
  </div>
  <button class="btn btn-secondary mt-1" id="no-button"
          [mat-dialog-close]="false">{{data.noButton}}</button>
  <button class="btn btn-primary mt-1 float-end" id="yes-button"
          [mat-dialog-close]="true">{{data.yesButton}}</button>
</div>

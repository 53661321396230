import {AbstractControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {Injectable} from '@angular/core';

// This class is used for validating form groups and displaying a error message

@Injectable()
export class CustomValidators {

  // Error message
  static validationMessages: any = {
    'username': {
      'required': 'Skal være udfyldt',
    },
    'password': {
      'required': 'Skal være udfyldt',
      'passwordNotMatch': 'Adgangskode skal være ens',
      'passwordPolicy': 'Adgangskode skal minimum være 10 tegn, Stort, lille bogstav, 1 Specialtegn og 1 tal',
    },
    'passwordRepeat': {
      'required': 'Skal være udfyldt',
    },
    'employeeGroup': {
      'required': 'Skal være udfyldt',
    },
    'email': {
      'required': 'Skal være udfyldt',
      'invalidFormat': 'Forkert format',
    }
  };

  // Is set to true when submit is clicked
  public submitClicked = false;

  static matchValidator(
    control: AbstractControl | null,
    controlTwo: AbstractControl | null
  ): ValidatorFn {
    return () => {
      control?.get('password')?.setErrors(null);
      if (control?.value === controlTwo?.value) {
        if (control?.value != null && control?.value !== '' && !control?.value.toString().match(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/)) {
          control.setErrors({'passwordPolicy': true});
        }
        return {match_error: 'Value does not match'};
      } else {
        control?.setErrors({'passwordNotMatch': true});
      }
      return null;
    };
  }

  static passwordMatch(control: AbstractControl) {
    const pass = control.get('password')?.value;
    const confirmPass = control.get('passwordRepeat')?.value;
    if (pass === confirmPass) {
      control.get('password')?.setErrors(null);
      if (pass != null && pass !== '' && !pass.toString().match(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/)) {
        control.get('password')?.setErrors({'passwordPolicy': true});
      }
      return null;
    } else {
      control.get('password')?.setErrors({'passwordNotMatch': true});
    }
  }

  static passwordMatch2(control: AbstractControl) {
    const pass = control.get('password')?.value;
    const confirmPass = control.get('passwordRepeat')?.value;
    if (pass === confirmPass && pass !== '') {
      control.get('password')?.setErrors(null);
      if (pass != null && pass !== '' && !pass.toString().match(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/)) {
        control.get('password')?.setErrors({'passwordPolicy': true});
      }
      return null;
    } else {
      control.get('password')?.setErrors({'passwordNotMatch': true});
    }
  }

  static email(control: AbstractControl) {
    const value = control.value;
    if (value != null) {
      if (value.length > 4 && value.length < 32 && value.toString().match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        return null;
      }
      return {'invalidFormat': true};
    }
    return null;
  }

  // Validating all fields in form group and setting the correct error message
  public logValidationErrors(group: UntypedFormGroup, formErrors: any): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof UntypedFormGroup) {
        this.logValidationErrors(abstractControl, formErrors);
      } else {
        formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && this.submitClicked ||
          (abstractControl?.touched || abstractControl?.dirty)) {
          const messages = CustomValidators.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              if (typeof messages === 'undefined') {
                console.log(key + ' har ingen error handling!!!');
              } else {
                formErrors[key] += messages[errorKey] + ' ';
              }
            }
          }
        }
      }
    });
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {GlobalFunctionsService} from '../../shared/services/globalFunctions.service';
import {CustomValidators} from '../../shared/services/custom.validators';
import {EmployeeService} from '../../shared/services/employee.service';
import {Employee} from '../../shared/models/employee/employee';


@Component({
  selector: 'forgotPassword',
  templateUrl: 'forgotPassword.component.html',
  styleUrls: ['./forgotPassword.component.scss']
})

export class ForgotPasswordComponent implements OnInit {
  captcha?: string;

  constructor(private fb: UntypedFormBuilder,
              private toastrService: ToastrService,
              public globalFunctionsService: GlobalFunctionsService, private employeeService: EmployeeService,
              public dialogRef: MatDialogRef<ForgotPasswordComponent>, @Inject(MAT_DIALOG_DATA) public data: Employee, public customVal: CustomValidators) {
  }

  resetPasswordForm = this.fb.group({
    email: ['', [Validators.required]],
  });

  formErrors = {
    'email': '',
  };

  ngOnInit() {
    GlobalFunctionsService.dialogCloseConfirm(this.dialogRef, this.resetPasswordForm);
  }

  onSubmit() {
    const email = this.resetPasswordForm.value.email;
    this.customVal.submitClicked = true;
    this.customVal.logValidationErrors(this.resetPasswordForm, this.formErrors);
    if (this.resetPasswordForm.valid && this.captcha) {
      this.employeeService.forgotPasswordEmployee(email).subscribe(data => {
          this.toastrService.success('Email sendt');
          this.dialogRef.close();
        }
      );
    } else {
      if (!this.captcha) {
        this.toastrService.error('Captcha ikke udfyldt');
      } else {
        this.toastrService.error('Email ikke korrekt udfyldt');
      }
      this.resetPasswordForm.reset();
    }
  }
}

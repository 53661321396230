<header *ngIf="loggedIn">
  <nav
    class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3 fixed-top">
    <div class="container-fluid p-0">
      <a class="navbar-brand" [routerLink]="['/']"><img src="../../../assets/img/logo.png" alt=""></a>
      <button class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              aria-label="Toggle navigation"
              [attr.aria-expanded]="isExpanded"
              (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-collapse collapse d-sm-inline-flex mr-auto" [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow itop-navbar-center">
          <!-- Links -->
          <li class="nav-item" [ngClass]="{'active' : currentRoute == ''}">
            <a class="nav-link" (click)="isExpanded=false;" [routerLink]="['/']">Link 1</a>
          </li>
          <li class="nav-item" [ngClass]="{'active' : currentRoute == '1'}">
            <a class="nav-link" (click)="isExpanded=false;" [routerLink]="['/']">Link 2</a>
          </li>
          <li class="nav-item" [ngClass]="{'active' : currentRoute == '2'}">
            <a class="nav-link" (click)="isExpanded=false;" [routerLink]="['/']">Link 3</a>
          </li>
        </ul>
      </div>

      <ul class="navbar-nav flex-grow d-lg-flex d-md-flex d-none">
        <!-- Administrator -->
        <li *ngIf="globalFunctionsService.getEmployee()?.employeeGroupId == 1" class="nav-item dropdown">
          <a class="nav-link"
             (click)="openUserAdministration();" role="button"
             data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-users-cog fa-2x text-primary itop-hover"></i>
          </a>
        </li>

        <!-- User -->
        <li class="nav-item">
          <div ngbDropdown placement="bottom-right" display="dynamic">
            <a class="notification nav-link navbarHeader pointer me-2" id="dropdownForm1" ngbDropdownToggle
               data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-user fa-2x text-primary" [ngClass]="isExpanded ? 'active' : ''"></i>
            </a>
            <div ngbDropdownMenu aria-labelledby="dropdownForm1">
              <div class="container h-25">
                <div class="row">
                  <div class="col-sm-12">
                    <p class="grayed-out-text">{{globalFunctionsService.getEmployee()?.username}}</p>
                  </div>
                </div>
              </div>
              <a class="dropdown-item userCustomDropdown" (click)="isExpanded=false;" routerLink="updateUserPassword">
                <i class="fas fa-cogs"></i> Ændre kode
              </a>
              <a class="dropdown-item userCustomDropdown" (click)="isExpanded=false; onLogout();">
                <i class="fas fa-power-off"></i> Log ud
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>

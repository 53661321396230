<div class="container">
  <div class="row">
    <div class="col mb-4">
      <div class="pageTitle d-flex align-items-center">
        <h1 class="mb-0">Opdater adgangskode</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-body">
        <form [formGroup]='employeePasswordForm' class="standardModalForm" (ngSubmit)="onSubmit()"
              autocomplete="off">
          <div class="row">
            <div class="form-group col-md-6" [ngClass]="{'has-error' : formErrors.password}">
              <label for="password">Adgangskode:</label>
              <input type="password" class="form-control" formControlName="password" name="{{globalFunctionsService.randomName}}"
                     id="password" autocomplete="off" tabindex="2"
                     (blur)="customVal.logValidationErrors(this.employeePasswordForm, formErrors)">
              <span class="help-block alert-danger" *ngIf="formErrors.password">
                                {{formErrors.password}}
                            </span>
            </div>
            <div class="form-group col-md-6" [ngClass]="{'has-error' : formErrors.passwordRepeat}">
              <label for="passwordRepeat">Gentag adgangskode:</label>
              <input type="password" class="form-control" formControlName="passwordRepeat"
                     name="{{globalFunctionsService.randomName}}"
                     id="passwordRepeat"
                     autocomplete="off" tabindex="2"
                     (blur)="customVal.logValidationErrors(this.employeePasswordForm, formErrors)">
              <span class="help-block alert-danger" *ngIf="formErrors.passwordRepeat">
                                {{formErrors.passwordRepeat}}
                            </span>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-xs-2">
              <button type="submit" class="btn btn-primary btn-block" tabindex="9" id="submit">
                Opdatere
                adgangskode
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col mb-4">
    <div class="pageTitle d-flex align-items-center">
      <h2 class="mb-0">Rediger bruger</h2>
    </div>
  </div>
</div>
<form [formGroup]='employeeForm' class="standardModalForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <div class="row">
    <div class="form-group col-md-6">
      <label for="username">Brugernavn:</label>
      <input type="text" class="form-control" formControlName="username" name="{{globalFunctionsService.randomName}}"
             id="username" autocomplete="off" tabindex="1" [ngClass]="{'is-invalid' : formErrors.username}"
             (blur)="customVal.logValidationErrors(employeeForm, formErrors)">
      <div class="invalid-feedback">
        {{formErrors.username}}
      </div>
    </div>
    <div class="form-group col-md-6">
      <label for="email">Email:</label>
      <input type="text" class="form-control" formControlName="email" name="{{globalFunctionsService.randomName}}"
             id="email" autocomplete="off" tabindex="2" [ngClass]="{'is-invalid' : formErrors.email}"
             (blur)="customVal.logValidationErrors(employeeForm, formErrors)">
      <div class="invalid-feedback">
        {{formErrors.email}}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-6">
      <label for="password">Adgangskode:</label>
      <input type="password" class="form-control" formControlName="password" name="{{globalFunctionsService.randomName}}"
             id="password" autocomplete="off" tabindex="3" [ngClass]="{'is-invalid' : formErrors.password}"
             (blur)="customVal.logValidationErrors(employeeForm, formErrors)">
      <div class="invalid-feedback">
        {{formErrors.password}}
      </div>
    </div>
    <div class="form-group col-md-6">
      <label for="passwordRepeat">Gentag adgangskode:</label>
      <input type="password" class="form-control" formControlName="passwordRepeat" name="{{globalFunctionsService.randomName}}"
             id="passwordRepeat" autocomplete="off" tabindex="4" [ngClass]="{'is-invalid' : formErrors.passwordRepeat}"
             (blur)="customVal.logValidationErrors(employeeForm, formErrors)">
      <div class="invalid-feedback">
        {{formErrors.passwordRepeat}}
      </div>
    </div>

  </div>
  <div class="row">
    <div class="form-group col-md-6">
      <label for="employeeGroup">Rolle:</label>
      <select class="form-control" id="employeeGroup" formControlName="employeeGroup" tabindex="5"
              [ngClass]="{'is-invalid' : formErrors.employeeGroup}">
        <option *ngFor="let employeeGroup of employeeGroups"
                [value]="employeeGroup.name">{{employeeGroup.name}}</option>
      </select>
      <div class="invalid-feedback">
        {{formErrors.employeeGroup}}
      </div>
    </div>
  </div>
  <button type="submit" class="btn btn-primary " tabindex="7" id="submit">
    <span *ngIf="!this.employee">Opret bruger</span><span
    *ngIf="this.employee">Opdatere bruger</span>
  </button>
</form>

import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatConfirmDialogComponent} from './matConfirmDialog/matConfirmDialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) {
  }

  public modalOpen = false;

  openConfirmDialog(msg: any, header = '', yesButton = 'Yes', noButton = 'Cancel') {
    return this.dialog.open(MatConfirmDialogComponent, {
      width: '390px',
      panelClass: 'confirm-dialog-container',
      disableClose: false,
      hasBackdrop: true,
      position: {top: '130px'},
      autoFocus: false,
      data: {
        header: header,
        message: msg,
        yesButton: yesButton,
        noButton: noButton
      }
    });
  }
}

import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Employee} from '../models/employee/employee';
import {EmployeeGroup} from '../models/employee/employeeGroup';
import {Token} from '../models/employee';

@Injectable({
  providedIn: 'root'
})

export class EmployeeService {
  constructor(private http: HttpClient) {
  }

  getEmployees() {
    return this.http.get<Employee[]>('api/employee');
  }

  getCurrentEmployee() {
    return this.http.get<Employee>('api/employee/employeeInformation');
  }

  insertEmployee(employee: Employee) {
    return this.http.post<Employee>('api/employee', employee);
  }

  updateEmployee(employee: Employee) {
    return this.http.put<Employee>('api/employee', employee);
  }

  disableEmployee(employeeId: number) {
    return this.http.delete<Employee>('api/employee' + employeeId);
  }

  getEmployeeGroup() {
    return this.http.get<EmployeeGroup[]>('api/employee/employeeGroup');
  }

  forgotPasswordEmployee(email: string) {
    return this.http.get('/api/employee/forgotPassword/' + email)
      .pipe(
        map((data: any) => {
          return data;
        }));
  }

  VerifyResetToken(token: Token) {
    return this.http.post('/api/employee/VerifyResetToken', token)
      .pipe(
        map((data: any) => {
          return data;
        }));
  }

  resetPasswordEmployee(employee: Employee) {
    return this.http.put('/api/employee/ResetPassword', employee)
      .pipe(
        map((data: any) => {
          return data;
        }));
  }
}

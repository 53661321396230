import {Component} from '@angular/core';

@Component({
  selector: 'index',
  templateUrl: 'index.component.html'
})

export class IndexComponent {
  constructor() {
  }
}


<div class="card card-body">
  <form [formGroup]='resetPasswordForm' class="standardModalForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="row">
      <div class="form-group col-md-12">
        <h2>Ny adganskode</h2>
      </div>
      <div class="form-group col-md-6">
        <label for="password">Adgangskode:</label>
        <input type="text" class="form-control" formControlName="password" name="{{globalFunctionsService.randomName}}"
               id="password" autocomplete="off" tabindex="2"
               [ngClass]="{'is-invalid' : formErrors.password}"
               (blur)="customVal.logValidationErrors(this.resetPasswordForm, formErrors)">
        <div class="invalid-feedback">
          {{this.formErrors.password}}
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="passwordRepeat">Gentag adgangskode:</label>
        <input type="text" class="form-control" formControlName="passwordRepeat" name="{{globalFunctionsService.randomName}}"
               id="passwordRepeat" autocomplete="off" tabindex="2"
               [ngClass]="{'is-invalid' : formErrors.passwordRepeat}"
               (blur)="customVal.logValidationErrors(this.resetPasswordForm, formErrors)">
        <div class="invalid-feedback">
          {{this.formErrors.passwordRepeat}}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-xs-2">
        <button type="submit" class="btn btn-primary btn-block" tabindex="9"
                id="submit">
          <span *ngIf="this.data != undefined">Opdater adgangskode</span>
        </button>
      </div>
    </div>
  </form>
</div>

import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../auth/authenticationService';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {DialogService} from '../../shared/dialogs/dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ForgotPasswordComponent} from '../forgotPassword/forgotPassword.component';
import {EmployeeService} from '../../shared/services/employee.service';
import {ResetPasswordComponent} from '../resetPassword/resetPassword.component';
import {Token} from '../../shared/models/employee';

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });

  constructor(private route: ActivatedRoute, private router: Router, private formBuilder: UntypedFormBuilder, private authenticationService: AuthenticationService,
              private toastrService: ToastrService, private dialogService: DialogService, private dialog: MatDialog, private employeeService: EmployeeService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const token: Token = {token: params['token']};
      if (token.token != null) {
        this.employeeService.VerifyResetToken(token).subscribe(employee => {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = false;
          dialogConfig.width = '20%';
          dialogConfig.maxHeight = '91vh';
          dialogConfig.data = employee;
          const dialogRef = this.dialog.open(ResetPasswordComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(() => {
            this.router.navigate([], {
              queryParams: {
                'token': null
              },
              queryParamsHandling: 'merge'
            });
          });
        }, error => {
          this.toastrService.error('Token er ikke valid, prøv venligst igen', 'Token Verify');
        });
      }
    });

    this.authenticationService.getLocalIp();
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.authenticationService.login(this.loginForm.value.username, this.loginForm.value.password, this.authenticationService.Ipv4)
        .pipe(first())
        .subscribe(
          () => {
            this.router.navigate(['']);
          },
          () => {
            this.toastrService.error('Brugernavn eller adgangskode er forkert', 'Login');
          });
    } else {
      this.toastrService.warning('Udfyld brugernavn eller adgangskode', 'Login');
    }
  }

  forgotPassword() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '20%';
    dialogConfig.maxHeight = '91vh';
    this.dialog.open(ForgotPasswordComponent, dialogConfig);
  }
}

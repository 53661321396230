import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';


import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavbarComponent} from './core/navbar/navbar.component';
import {LoginComponent} from './core/login/login.component';
import {AppRoutingModule} from './app-routing.module';
import {MaterialModule} from './shared/material/material.module';
import {CommonModule} from '@angular/common';
import {IndexComponent} from './modules/index/index.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {GlobalFunctionsService} from './shared/services/globalFunctions.service';
import {CustomValidators} from './shared/services/custom.validators';
import {AuthInterceptor} from './core/auth/auth.interceptor';
import {MatConfirmDialogComponent} from './shared/dialogs/matConfirmDialog/matConfirmDialog.component';
import {EmployeePasswordComponent} from './core/employeeSettings/password/employeePassword.component';
import {ToastrModule} from 'ngx-toastr';
import {EmployeeViewComponent} from './modules/administration/emplyoee/employeeView/employeeView.component';
import {EmployeeComponent} from './modules/administration/emplyoee/employee/employee.component';
import {DanishDatePipe} from './shared/pipe/danishDate';
import {DanishCurrencyPipe} from './shared/pipe/danishCurrency';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {getDanishPaginatorIntl} from './shared/material/matPaginatorTranslate';
import {ForgotPasswordComponent} from './core/forgotPassword/forgotPassword.component';
import {ResetPasswordComponent} from './core/resetPassword/resetPassword.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    IndexComponent,
    DanishDatePipe,
    DanishCurrencyPipe,

    EmployeeViewComponent,
    EmployeeComponent,
    MatConfirmDialogComponent,
    EmployeePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    CommonModule,
    NgbModule,
    ToastrModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [CustomValidators, GlobalFunctionsService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }, {provide: MatPaginatorIntl, useValue: getDanishPaginatorIntl()}],
  bootstrap: [AppComponent]
})
export class AppModule {
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {GlobalFunctionsService} from '../../shared/services/globalFunctions.service';
import {CustomValidators} from '../../shared/services/custom.validators';
import {EmployeeService} from '../../shared/services/employee.service';
import {Employee} from '../../shared/models/employee/employee';

@Component({
  selector: 'resetPassword',
  templateUrl: 'resetPassword.component.html',
  styleUrls: ['./resetPassword.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  constructor(private fb: UntypedFormBuilder,
              private toastrService: ToastrService,
              public globalFunctionsService: GlobalFunctionsService, private employeeService: EmployeeService,
              public dialogRef: MatDialogRef<ResetPasswordComponent>, @Inject(MAT_DIALOG_DATA) public data: Employee, public customVal: CustomValidators) {
  }

  resetPasswordForm = this.fb.group({
    email: ['', [Validators.required]],
  });

  formErrors = {
    'password': '',
    'passwordRepeat': '',
  };

  ngOnInit() {
    this.resetPasswordForm = this.fb.group({
      password: ['', [Validators.required]],
      passwordRepeat: [''],
    }, {validator: CustomValidators.passwordMatch});
    GlobalFunctionsService.dialogCloseConfirm(this.dialogRef, this.resetPasswordForm);
  }

  onSubmit() {
    this.customVal.submitClicked = true;
    this.customVal.logValidationErrors(this.resetPasswordForm, this.formErrors);
    if (this.resetPasswordForm.valid) {
      const employee: Employee = {
        employeeId: this.data.employeeId,
        createdBy: this.data.employeeId,
        createdOn: new Date(),
        modifiedBy: this.data.employeeId,
        modifiedOn: new Date(),
        active: true,
        email: this.data.email,
        username: this.data.username,
        employeeGroupId: this.data.employeeGroupId,
        password: this.resetPasswordForm.value.password,
      };

      this.employeeService.resetPasswordEmployee(employee).subscribe(
        () => {
          this.toastrService.success('Opdateret adgangskode', 'Bruger');
          this.dialogRef.close();
        }
      );
    }
  }
}

<div class="card card-body">
  <form #captchaProtectedForm="ngForm" [formGroup]='resetPasswordForm' class="standardModalForm" (ngSubmit)="onSubmit()"
        autocomplete="off">
    <div class="row">
      <h2>Glemt adganskode</h2>
      <div class="form-group col-md-12">
        <label for="email">Email:</label>
        <input type="text" class="form-control emailInput" formControlName="email" name="{{globalFunctionsService.randomName}}"
               id="email" autocomplete="off" tabindex="2"
               [ngClass]="{'is-invalid' : formErrors.email}"
               (blur)="customVal.logValidationErrors(this.resetPasswordForm, formErrors)">
        <div class="invalid-feedback">
          {{this.formErrors.email}}
        </div>
      </div>
      <div class="form-group col-md-8">
        <re-captcha
          [(ngModel)]="captcha"
          [ngModelOptions]="{standalone: true}"
          name="captcha"
          required
          siteKey="6LdG_WgaAAAAAIophUclln7hTydz1Ev53ypqTOzD">
        </re-captcha>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-xs-2">
        <button type="submit" class="btn btn-primary btn-block" tabindex="9"
                id="submit">
          <span *ngIf="this.data == undefined">Send</span>
        </button>
      </div>
    </div>
  </form>
</div>

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './core/login/login.component';
import {IndexComponent} from './modules/index/index.component';
import {AuthGuard} from './core/auth/auth.guard';
import {EmployeePasswordComponent} from './core/employeeSettings/password/employeePassword.component';
import {EmployeeViewComponent} from './modules/administration/emplyoee/employeeView/employeeView.component';
import {AuthGuardAdmin} from './core/auth/auth.guardAdmin';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'updateUserPassword', component: EmployeePasswordComponent, canActivate: [AuthGuard]},
  {path: 'administrationEmployee', component: EmployeeViewComponent, canActivate: [AuthGuardAdmin]},
  {path: '', component: IndexComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

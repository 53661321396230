import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthenticationService} from './authenticationService';
import {GlobalFunctionsService} from '../../shared/services/globalFunctions.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authenticationService: AuthenticationService, private globalFunctionsService: GlobalFunctionsService) {
  }

  // Automatic include token in all http calls
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (localStorage.getItem('token') != null) {
      const clonedReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
      });
      return next.handle(clonedReq).pipe(
        tap(
          () => {
          },
          err => {
            if (err.status === 401) {
              this.authenticationService.token = '';
              this.router.navigateByUrl('/login');
            } else {
              this.globalFunctionsService.errorToast(err.error.message);
              console.log(err);
            }
          }
        )
      );
    } else {
      return next.handle(req.clone());
    }
  }
}

import {Injectable} from '@angular/core';
import {MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Employee} from '../models/employee/employee';
import {ToastrService} from 'ngx-toastr';
import {UntypedFormGroup} from '@angular/forms';

@Injectable()
export class GlobalFunctionsService {
  constructor(public toastrService: ToastrService) {
  }

  // All employees for easing getting name from id
  static employees: Employee[] = [];
  static employee: Employee;
  static mobileView = false;
  public locked = false;

  // Font awesome icons
  public closeIcon = 'fas fa-times';
  public plusIcon = 'fas fa-plus';
  public editIcon = 'fas fa-pen';
  public deleteIcon = 'fas fa-trash-alt';

  // Table sort size and options
  public pageSizeOptions = [200, 500, 1000];
  public pageSize = 200;

  // Create a random name so google can't autofill
  randomName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  // Get employeeId of user
  static getEmployeeId(): number {
    if (localStorage.getItem('token')) {
      const tokenValue = localStorage.getItem('token');
      if (tokenValue != null) {
        const token = JSON.parse(atob(tokenValue.split('.')[1]));
        return Number(token.EmployeeId);
      }
    }
    return 0;
  }

  // Standard config of a dialog
  static matDialogConfigStandard(data: null | Employee = null, minWidth: string | null = null): any {
    const dialogConfig = new MatDialogConfig();
    if (minWidth != null) {
      dialogConfig.minWidth = minWidth;
    }

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = '100%';
    dialogConfig.maxHeight = '91vh';
    dialogConfig.data = data;
    return dialogConfig;
  }

  // Message when dialogBackDrop is clicked
  static dialogBackDropClick() {
    return !confirm('Er du sikker på du vil lukke modal');
  }

  // Check if modal is dirty and support escape
  static dialogCloseConfirm(dialogRef: MatDialogRef<any>, form: UntypedFormGroup) {
    dialogRef.backdropClick().subscribe(() => {
      if (form.dirty) {
        if (GlobalFunctionsService.dialogBackDropClick()) {
          return false;
        }
      }
      dialogRef.close();
    });

    dialogRef.keydownEvents().subscribe((event: { key: string; }) => {
      if (event.key === 'Escape') {
        dialogRef.close();
      }
    });
  }

  // Convert danish datetime to english
  static danishDatetimeToEnglish(danishDateTime: string) {
    if (danishDateTime && danishDateTime !== '') {
      const split = danishDateTime.split(' ');
      const splitDate = split[0].split('-');
      const splitTime = split[1].split(':');
      return new Date(Number(splitDate[2]), Number(splitDate[1]) - 1, Number(splitDate[0]), Number(splitTime[0]), Number(splitTime[1]));
    }
  }

  // Convert , to .
  static commaToDot(string: string) {
    if (string != null) {
      return string.toString().replace(',', '.');
    }
  }

  // Convert . to ,
  static dotToComma(string: string) {
    if (string != null) {
      return string.toString().replace('.', ',');
    }
  }

  static dialogClose(dialogRef: any) {
    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close();
    });

    dialogRef.keydownEvents().subscribe((event: { key: string; }) => {
      if (event.key === 'Escape') {
        dialogRef.close();
      }
    });
  }

  public getEmployee() {
    return GlobalFunctionsService?.employee;
  }

  public getEmployeeById(employeeId: number): Employee | undefined {
    if (GlobalFunctionsService.employees) {
      return GlobalFunctionsService.employees.find(a => a.employeeId === employeeId);
    }
    return undefined;
  }

  public findEmployeeNameFromId(id: number): string {
    if (GlobalFunctionsService.employees && GlobalFunctionsService.employees.length > 0) {
      return GlobalFunctionsService.employees.find(a => a.employeeId === id)?.username ?? '';
    }
    return '';
  }

  public successToast(message: string) {
    this.toastrService.success(message, '', {
      timeOut: 1500
    });
  }

  public errorToast(message: string) {
    this.toastrService.error(message, 'Fejl', {
      timeOut: 1500
    });
  }

  public warningToast(message: string) {
    this.toastrService.warning(message, 'Advarsel', {
      timeOut: 1500
    });
  }
}

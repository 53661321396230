<div class="row">
  <div class="col mb-4">
    <div class="pageTitle d-flex align-items-center">
      <h1 class="mb-0">Bruger administration</h1>
      <button class="btn btn-outline-primary btn-sm ms-3" (click)="onCreate()">Tilføj ny</button>
    </div>
  </div>
  <div class="col mb-4">
    <div class="float-end my-2 my-lg-0 input-close-icon input-group-sm">
      <input class="form-control" type="Søg" placeholder="Søg" aria-label="Søg" autocomplete="off"
             [(ngModel)]="searchKey" (keyup)="applyFilter()">
      <a class="btn btn-link btn-sm" aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
        <i class="{{globalFunctionsService.closeIcon}}"></i>
      </a>
    </div>
  </div>
</div>
<div class="card">
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
      <mat-cell data-label="Email" *matCellDef="let element">{{element.email}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Brugernavn</mat-header-cell>
      <mat-cell data-label="Brugernavn" *matCellDef="let element">{{element.username}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="employeeGroupId">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Rolle</mat-header-cell>
      <mat-cell data-label="Rolle"
                *matCellDef="let element">{{getNameByEmployeeGroup(element.employeeGroupId)}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="modifiedBy">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Opdateret af</mat-header-cell>
      <mat-cell data-label="Opdateret af"
                *matCellDef="let element">{{globalFunctionsService.findEmployeeNameFromId(element.modifiedBy)}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="modifiedOn">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Oprettet</mat-header-cell>
      <mat-cell data-label="Oprettet" *matCellDef="let element">{{element.modifiedOn | danishDate: false}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <a class="btn" (click)="onEdit(row)"><i class="{{globalFunctionsService.editIcon}}"></i></a>
        <a class="btn" (click)="onDelete(row)"><i class="{{globalFunctionsService.deleteIcon}}"></i></a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="loading">
      <mat-footer-cell *matFooterCellDef>
        Indlæser data...
      </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef>
        Ingen data...
      </mat-footer-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
    <mat-footer-row *matFooterRowDef="['noData']"
                    [ngClass]="{'hide':!(dataSource!=null&&dataSource.data.length==0)}"></mat-footer-row>
  </mat-table>
  <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]=globalFunctionsService.pageSizeOptions
                 [pageSize]=globalFunctionsService.pageSize
                 showFirstLastButtons></mat-paginator>
</div>
